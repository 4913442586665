<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on">
          Approve Withdrawal
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Approve Withdrawal
        </v-card-title>

        <v-card-text> Are you sure you want to Approve Withdrawal </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="Activate()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
export default {
  props: ["withdraw"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    Activate() {
      let payload = {
        token: this.getToken,
        withdrawId: this.withdraw._id,
      };
      this.$store.dispatch("ApproveWithdrawal", payload);
      this.dialog = false;
    },
  },
};
</script>